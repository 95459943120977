import React from 'react';

import Fpcardset1 from '../Fpcardset1.js'
import Fpcards1i1 from '../assets/img/aucloc1.jpg'
import Fpcards1i2 from '../assets/img/aucloc2.jpg'
import Fpcards1i3 from '../assets/img/aucloc3.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Button} from 'react-bootstrap';
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMapMarked} from '@fortawesome/free-solid-svg-icons'

import recentpost1 from '../assets/img/recent_post_01.jpg'
import recentpost2 from '../assets/img/recent_post_02.jpg'          

function FPCfooter () {
    return (



      
    <Row>
    <Col md={12} sm={12} style={{backgroundColor: '#2b2e31'}}>

      <div className="container-fluid d-flex justify-content-center" style={{paddingLeft: '30px', paddingRight: '30px'}}>

        <Container>
        <Row>
          <Col sm={12} md={6}>

            
              <h4 className="title" style={{color: 'white'}}>About us</h4>
              <p style={{color: '#cccccc'}}>
                  {/* CLUBCAMAYAN is providing this online marketplace for buying of tourist and guests packages of goods and services provided by Subic Bay Marine Exploratorium, Inc. (“SBMEI”).  SBMEI owns the integrated resort operating as Camayan Beach Resort, Ocean Adventure Marine Mammal Park, and Adventure Beach Water Park. CLUBCAMAYAN is the name of this Platform and market forum and SBMEI is the seller of the packages.
                  
                  This system is unique, as it allows the buyers to control the schedule of the sale of these packages.  It is an auction style format, where our guests and customers are making below retail market offers for these packages, but with the “auction” ending when SBMEI decides to accept the latest offer.  There is no fixed end time for making offers.  Make an offer, be surprised! */}
                  
                  {/* <strong>CLUB CAMAYAN</strong> is an online marketplace for our customers to buy discounted goods and services provided by Subic Bay Marine Exploratorium, Inc. (“SBMEI”).  SBMEI owns the integrated resort operating as Camayan Beach Resort, Ocean Adventure Marine Mammal Park, and Adventure Beach Water Park. CLUBCAMAYAN is the name of this marketplace and SBMEI is the seller of the packages.  */}
                  Experience the ultimate blend of excitement and relaxation at our sports and recreation park. Whether you're seeking high-energy adventures or a place to unwind, we have something for everyone. Come play, explore, and make unforgettable memories!
                  <br /><br />
                  {/* This system is unique, as it allows the buyers to control the schedule of the sale of these packages.  It is an auction style format, where our guests and customers are making below retail market offers for these packages, but with the “auction” ending when SBMEI decides to accept the latest offer.  There is no fixed end time for making offers.  Make an offer, be surprised! */}


                </p>
              {/* <a className="button small" href="#">read more</a> */}
            

          </Col>
          <Col sm={12} md={6}>
            
              <h4 className="title" style={{color: 'white'}}>Get In Touch</h4>
             
              

              
              <Container>
                <Row>
                <Col>
                    <span style={{color: '#cccccc'}}>PHILIPPINES</span><br/>
                    <ul className="contact_details" style={{color: '#cccccc'}}>
                      <li><i className="fa fa-envelope-o"></i>&nbsp;&nbsp;reservation@camayanbeachresort.ph</li>  
                      <li><i className="fa fa-phone-square"></i>&nbsp;&nbsp;(047) 252 8000 / (02) 83967412</li>
                      
                      <li><FontAwesomeIcon icon={faMapMarked}></FontAwesomeIcon>&nbsp;&nbsp;<a href="" target="_blank">Camayan Wharf, West Ilanin Forest Area Subic Bay, Philippines 2222</a></li>
                    </ul>
                  </Col>
                  {/* <Col>
                  <span style={{color: '#cccccc'}}>PROJEXASIA</span><br/>
                    <ul className="contact_details" style={{color: '#cccccc'}}>
                      <li><i className="fa fa-envelope-o"></i>&nbsp;&nbsp;info@projexasia.com.ph</li>
                      <li><i className="fa fa-phone-square"></i>&nbsp;&nbsp;+63 456240200</li>
                      <li><FontAwesomeIcon icon={faMapMarked}></FontAwesomeIcon>&nbsp;&nbsp;<a href="https://goo.gl/maps/4k47whqnFY7jHidv9" target="_blank">Clark Freeport Zone</a></li>
                    </ul>
                  </Col> */}
                  {/*  <Col>
                  <span style={{color: '#cccccc'}}>THAILAND</span><br/>
                    <ul className="contact_details" style={{color: '#cccccc'}}>
                      <li><i className="fa fa-envelope-o"></i>&nbsp;&nbsp;thailand@bidlive.bid</li>
                      
                      <li><i className="fa fa-phone-square"></i>&nbsp;&nbsp;+66 98 308 4800</li>
                      <li><FontAwesomeIcon icon={faMapMarked}></FontAwesomeIcon>&nbsp;&nbsp;<a href="https://goo.gl/maps/Z5DNHhLqdJng1ibM8" target="_blank">Pattaya Thailand</a></li>
                    </ul>
                  </Col> */}
                </Row>

                {/* <Row>
                  <Col>
                    <span style={{color: '#cccccc'}}>PHILIPPINES</span><br/>
                    <ul className="contact_details" style={{color: '#cccccc'}}>
                      <li><i className="fa fa-envelope-o"></i>&nbsp;&nbsp;info@bidlive.bid</li>  
                      <li><i className="fa fa-phone-square"></i>&nbsp;&nbsp;+63 908 655 1352</li>
                      
                      <li><FontAwesomeIcon icon={faMapMarked}></FontAwesomeIcon>&nbsp;&nbsp;<a href="https://goo.gl/maps/uzknNqxCQ6LHfZfX7" target="_blank">Angeles City, Philippines</a></li>
                    </ul>
                  </Col>
                  <Col>
                  
                  </Col>

                </Row>  */}
              </Container>
          
            



          </Col>
          
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" ><p>Copyright © 2020 - All rights reserved.</p></div>
            </Col>
          </Row>

          <div className="container-fluid d-flex justify-content-center" >
            
          
            {/* <div className="widget col-lg-3 col-md-3 col-sm-12">
              <h4 className="title">Press Releases</h4>
              <ul className="recent_posts">
                <li>
                  <a href="#">
                  <img src={recentpost1} alt=" /" />Our New Live Auction System is here..</a>
                  <a className="readmore" href="#">read more</a>
                </li>
                <li>
                  <a href="#">
                  <img src={recentpost2} alt=" /" />Keeping Auctions Live</a>
                  <a className="readmore" href="#">read more</a>
                </li>
              </ul>
          
            </div> */}
          
            
          </div>

          

          {/* <div className="copyrights">
            <div className="container">
              <div className="col-lg-6 col-md-6 col-sm-12 columns footer-left">
                <p>Copyright © 2020 - All rights reserved.</p>
              </div>
            </div>
          </div> */}

          </Container>

       </div>
    </Col>
  </Row>
    )

};
export default FPCfooter;
