import React, {useState} from 'react';

import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ModalConsign from './ModalConsign.js'
import ModalConsignTy from './ModalConsignTy.js'

function FPCmiscbar1 () {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowty, setModalShowty] = React.useState(false);


    return(
     <Row>
      <Col sm={12} md={12} style={{backgroundColor: '#dedede', padding: '15px'}}>
          <div className="container-fluid d-flex justify-content-center" style={{maxWidth: '1140px'}}>
              <h3 style={{color: '#2e3957', padding: '8px 15px 8px 0px', cursor: 'pointer' }} onClick={() => setModalShow(true)}></h3>
               {/* <Button variant='warning' size='lg' style={{border: '2px'}}>Contact Us</Button> */}

               <ModalConsign
                show={modalShow}
                onHide={() => setModalShow(false)}
                onNext={() => setModalShowty(true)}
              />
              <ModalConsignTy
                show={modalShowty}
                onHide={() => setModalShowty(false)}
              />

          </div>
      </Col>
    </Row>


    )

}
export default FPCmiscbar1;